import React, { FC, memo, useEffect, useCallback } from 'react';
import Link from 'next/link';
import styled from 'styled-components';

import { useConfig } from '@server/lib/config';
import { categoryHref, INTERNAL_URI } from '@helpers/route';
import useBreakpoint, { Breakpoints } from '@hooks/use-break-point';

import _get from 'lodash/get';
import _take from 'lodash/take';
import _isEmpty from 'lodash/isEmpty';
import { useCategoryProductListingQuery } from 'src/generated/graphql';

import LazyLoadComponent from '@lib/lazy-component';
import Block from '@views/components/block/Block';
import ProductSwiper from './component/swiper';
import Placeholder from './placeholder';

import cn from 'classnames';
import {
  EVENT_CLASS_NAME,
  EVENT_ACTION_NAME,
  EVENT_CATEGORY,
  EVENT_LABEL,
} from '@helpers/events';
import { absoluteUrl } from '@lib/helpers';

import * as S from './styles';

const ProductList = ({ id, data }) => {
  const products = _get(data, 'category.items', []);
  // const handleImpression = useCallback(() => {
  //   if (products.length > 0) {
  //     if (window.dataLayer) {
  //       dataLayer.push({ ecommerce: null }); // Clear the previous ecommerce object.
  //       dataLayer.push({
  //         ecommerce: {
  //           currencyCode: 'VND', // Local currency is optional.
  //           impressions: products.map((item: any, index: number) => ({
  //             id: _get(item, 'id'),
  //             name: _get(item, 'name'),
  //             price: _get(item, 'price'),
  //             brand: '',
  //             category: id,
  //             list: 'Homepage category',
  //             position: index + 1,
  //           })),
  //         },
  //       });
  //     }
  //   }
  // }, [products]);

  // useEffect(() => {
  //   handleImpression();
  // }, [products.length]);

  return (
    <Block isSection className="mb-3">
      <S.BlockHeader className="pb-3">
        <S.BlockTitle as="h3">{_get(data, ['category', 'name'])}</S.BlockTitle>
        <Link
          href={INTERNAL_URI.CATEGORY}
          as={categoryHref(
            _get(data, 'category.id'),
            _get(data, 'category.urlKey')
          )}
        >
          <a
            aria-label="Xem thêm"
            title="Xem thêm"
            className={cn('block__link', EVENT_CLASS_NAME)}
            data-category={EVENT_CATEGORY.ACTION}
            data-action={EVENT_ACTION_NAME}
            data-label={EVENT_LABEL.HOT_PRODUCT_MORE_ACTION}
          >
            Xem thêm
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="10"
              height="12"
              viewBox="0 0 10.5 14"
            >
              <path d="M7,0l7,10.5H0Z" transform="translate(10.5) rotate(90)" />
            </svg>
          </a>
        </Link>
      </S.BlockHeader>
      <ProductSwiper products={products} />
    </Block>
  );
};

const ProductCategory = memo<any>(
  ({ id, bannerUrl, bannerThumbnailUrl, isMobile }) => {
    const { data, loading, error } = useCategoryProductListingQuery({
      variables: {
        id,
        page: 1,
        limit: isMobile ? 6 : 36,
        orderBy: 'price|asc',
      },
    });

    if (_isEmpty(data?.category?.items)) return;

    if (loading || !!error) {
      return <Placeholder />;
    }

    const backgroundUrl = isMobile
      ? data?.category?.bannerThumbnailUrl || data?.category?.bannerUrl
      : data?.category?.bannerUrl;

    return (
      <>
        {backgroundUrl && (
          <S.BannerWrapper className="mb-4">
            <Link
              href={INTERNAL_URI.CATEGORY}
              as={categoryHref(
                _get(data, 'category.id'),
                _get(data, 'category.urlKey')
              )}
            >
              <a
                aria-label="Xem thêm"
                title="Xem thêm"
                className={cn('block__link', EVENT_CLASS_NAME)}
                data-category={EVENT_CATEGORY.ACTION}
                data-action={EVENT_ACTION_NAME}
                data-label={EVENT_LABEL.HOT_PRODUCT_MORE_ACTION}
              >
                <img
                  src={backgroundUrl}
                  alt={data?.category?.name}
                  style={{ width: '100%' }}
                  width="100%"
                  className="img-fluid"
                />
              </a>
            </Link>
          </S.BannerWrapper>
        )}
        <ProductList data={data} id={id} />
      </>
    );
  }
);

const Container: FC<any> = memo(() => {
  const { CATEGORIES = [] } = useConfig();
  const breakpoint = useBreakpoint();
  const isMobile = [Breakpoints.xs, Breakpoints.sm].includes(breakpoint);

  return (
    <>
      {CATEGORIES.map(({ id, bannerUrl, bannerThumbnailUrl }) => (
        <ProductCategory
          key={id}
          id={id}
          bannerUrl={bannerUrl}
          bannerThumbnailUrl={bannerThumbnailUrl}
          // component={ProductCategory}
          // placeholder={Placeholder}
          isMobile={isMobile}
          height={300}
        />
      ))}
    </>
  );
});

export default Container;
