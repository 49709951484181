import React, { useState, memo } from 'react';
import { Carousel } from 'react-bootstrap';

import { BANNER_SLIDES } from '@mocks/sample-data';
import { LazyLoadImage } from '@views/components/lazy-image';

import cn from 'classnames';
import {
  EVENT_CLASS_NAME,
  EVENT_ACTION_NAME,
  EVENT_CATEGORY,
  EVENT_LABEL,
} from '@helpers/events';

export const Banner = memo(() => {
  const [slideIndex, setSlideIndex] = useState(0);

  const handleSelect = (selectedIndex: number, e) => {
    setSlideIndex(selectedIndex);
  };

  return (
    <div>
      <Carousel
        activeIndex={slideIndex}
        indicators
        controls
        interval={5000}
        onSelect={handleSelect}
      >
        {BANNER_SLIDES.map((item, i) => (
          <Carousel.Item key={`${item.key}-${i}`} title={item.alt}>
            <a
              href={item.url}
              className={cn(EVENT_CLASS_NAME)}
              data-category={EVENT_CATEGORY.BANNER}
              data-action={EVENT_ACTION_NAME}
              data-label={EVENT_LABEL.MAIN_HOMEPAGE_BANNER}
            >
              <LazyLoadImage
                image={{
                  alt: item.alt,
                  src: item.src,
                  srcSet: item.srcSet,
                }}
              />
            </a>
          </Carousel.Item>
        ))}
      </Carousel>
    </div>
  );
});

export default Banner;
