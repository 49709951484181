import styled from 'styled-components';

export const Wrapper = styled.div``;

export const Background = styled.div`
  width: 100%;
  display: flex;
  position: absolute;
  z-index: -1;
  background: linear-gradient(rgb(45, 194, 109) 50%, transparent 50%);

  .background-image {
    @media (min-width: 992px) {
      width: 1240px;
      height: 394px;
      margin: auto;
      display: block;
    }
    display: none;
  }
`;

export const BannerContainer = styled.div`
  .banner-container {
    @media (min-width: 992px) {
      width: 824px;
      height: 274px;
      background: white;
      border-radius: 4px 4px 0px 0px;
      margin: auto;
      position: relative;
      overflow: hidden;
    }

    @media (max-width: 992px) {
      position: relative;
      z-index: 1;
      padding: 0px 15px;
      background: linear-gradient(rgb(45, 194, 109) 60%, transparent 40%);
      & > div {
        border-radius: 4px 4px 0px 0px;
        overflow: hidden;
      }

      .carousel-control-next,
      .carousel-control-prev {
        display: none;
      }
    }

    img {
      width: 100%;
      // height: 274px;
    }
  }
`;

export const Navigation = styled.div`
  .navigation-container {
    @media (min-width: 992px) {
      width: 824px;
      height: 125px;
      margin: auto;
    }

    @media (max-width: 992px) {
      margin: 0 15px;
    }

    display: flex;
    border-radius: 0px 0px 4px 4px;
    overflow: hidden;
    padding: 15px 0px;
    background: white;
    text-align: center;
    overflow: auto;
    justify-content: center;

    .item {
      display: flex;
      flex-direction: column;
      align-items: center;

      @media (min-width: 992px) {
        flex-basis: calc(137.333px);
      }

      @media (max-width: 992px) {
        flex-basis: calc(96px);
      }
      flex-shrink: 0;
      padding: 0px 8px;
      img {
        @media (min-width: 992px) {
          width: 48px;
          height: 48px;
          border-radius: 14px;
        }

        @media (max-width: 992px) {
          width: 40px;
          height: 40px;
          border-radius: 14px;
        }
      }
      & > span {
        @media (min-width: 992px) {
          margin-top: 8px;
          font-size: 14px;
          font-weight: normal;
          font-stretch: normal;
          font-style: normal;
          line-height: 20px;
          color: rgb(36, 36, 36);
          display: -webkit-box;
          text-overflow: ellipsis;
          overflow: hidden;
        }

        @media (max-width: 992px) {
          font-size: 12px;
          line-height: 16px;
          color: rgb(36, 36, 36);
          margin: 8px 0px 0px;
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 2;
          overflow: hidden;
          max-height: 32px;
          word-break: break-word;
        }
      }
    }
  }
`;
