import React, { useEffect, useState, memo } from 'react';
import SuggestKeywords from '@views/modules/suggest-keywords';

import CategoryList from './sub-modules/category';
import ProductCategory from './sub-modules/product-category';
// import ProductList from './sub-modules/product-list/lazy';
import RecentlyView from '@views/modules/recent-view/lazy';

const HomeComponent = () => {
  const [loaded, setLazyLoaded] = useState<boolean>(false);
  useEffect(() => {
    const timeout = setTimeout(() => {
      setLazyLoaded(true);
    }, 300);

    return () => {
      clearTimeout(timeout);
    };
  }, []);

  return (
    <>
      <CategoryList />
      {/* <SuggestKeywords /> */}

      {loaded && (
        <>
          <RecentlyView />
          <ProductCategory />
        </>
      )}
    </>
  );
};

const Home = memo(HomeComponent);
Home.displayName = 'Home';

export default Home;
