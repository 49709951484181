import React, { memo } from 'react';
import _get from 'lodash/get';
import useBreakpoint, { Breakpoints } from '@hooks/use-break-point';

import Banner from './banner';

import * as S from './styled';

export const BeforeBody = () => {
  const breakpoint = useBreakpoint();
  const isMobile = [Breakpoints.xs, Breakpoints.sm].includes(breakpoint);

  return (
    <S.Wrapper isMobile={isMobile}>
      <S.Background>
        <div className="background-image">
          <img
            src="/assets/BACKDROP-01.png"
            // src="https://salt.tikicdn.com/cache/w1080/ts/upload/ae/18/1b/0153eedc5b553971d2966d9be1ac72a4.png"
            alt="top-banner-bg"
            width="1240"
            height="394"
          />
        </div>
      </S.Background>

      <S.BannerContainer>
        <div className="banner-container">
          <Banner />
          <div style={{ height: '' }} />
        </div>
      </S.BannerContainer>
      <S.Navigation>
        <div className="navigation-container">
          <a href="/tra-cuu-don-hang" className="item" data-view-index="0">
            <img src="/assets/icon/icon-01.png" />
            <span>Đơn hàng</span>
          </a>
          <a href="/bao-gia" className="item" data-view-index="1">
            <img src="/assets/icon/icon-02.png" />
            <span>Báo giá rẻ</span>
          </a>
          <a href="/danh-sach-yeu-thich" className="item" data-view-index="2">
            <img src="/assets/icon/icon-03.png" />
            <span>Sản phẩm yêu thích</span>
          </a>
          <a
            href="/bai-viet/huong-dan-mua-van-phong-pham"
            className="item"
            data-view-index="3"
          >
            <img src="/assets/icon/icon-04.png" />
            <span>Hướng dẫn mua hàng</span>
          </a>
          <a
            href="/bai-viet/cau-hoi-thuong-gap-ve-van-phong-pham-faqs"
            className="item"
            data-view-index="4"
          >
            <img src="/assets/icon/icon-05.png" />
            <span>Trợ giúp</span>
          </a>
          <a href="/lien-he" className="item" data-view-index="5">
            <img src="/assets/icon/icon-06.png" />
            <span>Liên hệ</span>
          </a>
        </div>
      </S.Navigation>
    </S.Wrapper>
  );
};

export default memo(BeforeBody);
