import React from 'react';

import styled from 'styled-components';
// import ReactMarkdown from 'react-markdown';
import { Container } from 'react-bootstrap';

// import { useHomepageQuery } from 'src/generated/graphql';

import Partner from './components/partner';
import Customer from './components/customer';

import _get from 'lodash/get';
import _toUrl from '@helpers/convert-to-url';

const Box = styled.div`
  border-top: 4px solid #ee4d2d;
  background-color: #fff;
  .container {
    padding-top: 1.875rem;
    padding-bottom: 1.875rem;
    border-bottom: 1px solid rgba(0, 0, 0, 0.09);
  }
  h2 {
    margin: 0;
    line-height: 1.7;
    font-size: 1.125rem;
    font-weight: 700;
  }
  p {
    line-height: 1.4;
  }
`;

// const Section = styled.div`
//   padding-bottom: 1.875rem;
// `;

// function flatten(text, child) {
//   return typeof child === 'string'
//     ? text + child
//     : React.Children.toArray(child.props.children).reduce(flatten, text);
// }

// function HeadingRenderer(props: any) {
//   var children = React.Children.toArray(props.children);
//   var text = children.reduce(flatten, '');
//   var slug = _toUrl(text.toLowerCase());
//   return React.createElement('h' + props.level, { id: slug }, props.children);
// }

export const AfterBody = React.memo(() => {
  // const { data } = useHomepageQuery();

  return (
    <Box>
      <Container>
        <Partner />
        <Customer />
        {/* <ReactMarkdown
          source={_get(data, 'homepage.content', '')}
          renderers={{ heading: HeadingRenderer }}
        /> */}
      </Container>
    </Box>
  );
});

export default AfterBody;
